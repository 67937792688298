<template>
  <div class="home-section">
    <slider :images="images" v-if="images.length" classes="home--slider"></slider>

    <b-container class="text-center mt-0 mt-lg-5 d-none d-lg-block">
      <h1 class="jd-text-dark jd-font-bold jd-text-36 jd-text-25__mobile">{{ HOME_PAGE.title }}</h1>
      <h2 class="jd-text-dark jd-font-regular jd-text-25 jd-text-25__mobile">{{ HOME_PAGE.sub_title }}</h2>
    </b-container>

    <home-specialize :items="HOME_SPECIALIZES" class="mt-0 pt-3 mb-5"/>

    <!--Specialize Section Start-->
    <specialize :services="specialize" :classes="'mb-0 mb-lg-5'" :is-home="true"></specialize>
    <!--Specialize Section End-->

    <!--Video Section Start-->
    <jd-video></jd-video>
    <!--Video Section End-->

    <!--Banner Section Start-->
    <banner-right class="d-none d-lg-block mt-desktop-negative" :image="HOME_PAGE.banner_first_path"></banner-right>
    <banner-right class="d-block d-lg-none mt-desktop-negative mt-mobile-negative" width :image="HOME_PAGE.banner_first_path" only-image></banner-right>
    <!--Banner Section End-->

    <!--Works Section Start-->
    <works></works>
    <!--Works Section End-->

    <banner-left class="d-block d-lg-none mt-desktop-negative" width :image="HOME_PAGE.banner_second_path" only-image></banner-left>

    <!--Testimonials Section Start-->
    <testimonials></testimonials>
    <!--Testimonials Section End-->
  </div>
</template>

<script>
import BannerRight from "./_partials/Home/BannerRight";
import BannerLeft from "./_partials/Home/BannerLeft";
import Works from "./_partials/Home/Works";
import Testimonials from "./_partials/Home/Testimonials";
import JdVideo from "./_partials/Home/Video";
import Services from "./_partials/Home/Services";
import Specialize from "./_partials/Home/Specialize";
import Slider from "./_partials/Home/Slider";
import Animation from "./_partials/Home/Animation";
import {mapGetters} from 'vuex';
import HomeSpecialize from "@/views/_partials/Home/HomeSpecialize";

export default {
  metaInfo() {
    return {
      title: 'Careful Movers',
      meta: [
        {
          vmid: "description",
          name: "description",
          content: 'Careful Movers is a local San Francisco Bay Area full service moving company. We specialize in full range of household and commercial moving services, customized to fit your particular moving needs. Our affordable rates, professional moving crews and most efficient moving equipment will always ensure a stress free move.'
        },
        {
          vmid: "title",
          name: "title",
          content: 'CarefulMovers.com'
        }
      ]
    };
  },
  components: {
    HomeSpecialize,
    Specialize, Slider, BannerRight, BannerLeft, Works, Testimonials, JdVideo, Services, Animation
  },
  beforeCreate() {
    this.$store.dispatch("GET_SERVICES_NAMES");
    this.$store.dispatch("GET_HOME_PAGE_INFO");
    this.$store.dispatch("GET_SPECIALIZE_DETAILS");
    this.$store.dispatch("GET_HOME_SPECIALIZE_DETAILS");
  },
  data() {
    return {
      specialize: []
    }
  },
  methods: {
    hideLoader() {
      if (this.SERVICES.length && this.SLIDERS.length && Object.keys(this.HOME_PAGE).length && this.SPECIALIZES.length) {
        this.$root.$emit('hideLoader');
      }
    }
  },
  computed: {
    ...mapGetters(['SERVICES', "SLIDERS", "HOME_PAGE", "SPECIALIZES", "HOME_SPECIALIZES"]),
    images: function () {
      return this.SLIDERS.map(item => item.path);
    }
  },
  watch: {
    'SERVICES': function () {
      this.hideLoader()
    },
    'SLIDERS': function () {
      this.hideLoader()
    },
    'HOME_PAGE': function () {
      this.hideLoader()
    },
    'SPECIALIZES': function (newVal) {
      this.specialize = this.chunk(newVal, Math.ceil(newVal.length / 3));
      this.hideLoader()
    }
  }
}
</script>

<style lang="scss">
.mt-desktop-negative {
  margin-top: -115px;
  z-index: -1;
}

@media screen and (max-width: 992px) {
  .mt-desktop-negative {
        margin-top: 0;
  }
  .mt-mobile-negative {
    margin-top: -100px;
    margin-bottom: -80px;
  }
  .home--slider {
    height: 45vh !important;

    .trinagle-bottom {
      height: 50px !important;
    }
  }

  .mt-mobile-0 {
    margin-top: 10px !important;
  }
}
</style>


<style scoped lang="scss">
.jd-specialize-title {
  font-size: 29px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.03;
  letter-spacing: 1.74px;
  text-align: center;
  color: #959595;
}
</style>
